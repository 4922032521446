<style lang="scss" scoped>
.menu-card {
  box-shadow: 0 0 1rem rgba($secondary-dark, 0.2);
  overflow: hidden;
  border-radius: 0.5rem;
  display: flex;
  max-height: 96px;
  border: solid 1px $secondary;
  position: relative;
  background-color: white;
  .menu-image {
    grid-area: image;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 96px;
    min-width: 96px;
  }

  .menu-item {
    display: grid;
    position: relative;
    width: 100%;
    padding: 0.5rem;
    grid-gap: 0.5rem;
    align-items: start;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "name price"
      "caption price";
    .item-names {
      display: flex;
      justify-content: left;
      align-items: flex-end;
      flex-wrap: wrap;
    }
    .item-name {
      grid-area: name;
      font-size: 1.25rem;
      padding-right: 0.25rem;
      align-self: start;
      font-family: "cheap-pine-sans", sans-serif;
      div {
        float: left;
      }
    }
    .item-sub-name {
      font-size: 0.75rem;
      font-family: "alternate-gothic-atf", sans-serif;
      font-style: italic;
      //text-transform: uppercase;
    }
    .item-caption {
      grid-area: caption;
      text-transform: capitalize;
    }
    .item-content {
      li {
        display: flex;
        align-items: center;
        &:before {
          content: "●";
          font-size: 0.5rem;
          padding: 0 0.25rem;
        }
      }
    }
    .item-price {
      grid-area: price;
      font-size: 1.25rem;
      color: $primary-dark;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      ul {
        display: flex;
        align-items: baseline;
        .price-name {
          color: $secondary;
          font-size: 0.75em;
          padding: 0 0.25em;
          &:after {
            content: " :";
          }
        }
      }
    }
    &.veggie {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("../../assets/veggie.svg");
        background-size: 40%;
        background-position: 90% top;
        background-repeat: no-repeat;
        opacity: 0.3;
      }
    }
  }

  &.wide {
    @media screen and (min-device-width: 768px) {
      grid-column: span 2;
    }
  }

  &.focus {
    flex-direction: column;
    max-height: none;
    .menu-image {
      min-height: 240px;
      flex: 1;
    }
    @media screen and (min-device-width: 768px) {
      grid-column: span 2;
      .menu-image {
        min-height: 340px;
      }
    }
    grid-row: span 4;
  }

  &.aside,
  &.tag {
    background-color: transparent;
    border-radius: 0;
    border-top: solid 1px;
    border-bottom: solid 1px;
    border-left: none;
    border-right: none;
    box-shadow: none;
    @media screen and (min-device-width: 768px) {
      grid-column: span 2;
    }
    .item-name {
      font-size: 2rem;
    }
    .item-caption {
      display: none;
    }
    .item-price {
      color: $secondary-dark;
    }
    .item-content {
      display: flex;
      li:first-child:before {
        display: none;
      }
    }
  }
  &.tag {
    border-top: none;
    .item-name {
      font-size: 1.5rem;
    }
  }
}
</style>

<template>
  <div class="menu-card" :class="type">
    <div
      v-if="item.image"
      class="menu-image"
      :style="`background-image: url(${item.image})`"
    ></div>
    <div class="menu-item" v-if="item.name">
      <div class="item-names">
        <span class="item-name">{{ item.name }}</span>
        <span class="item-sub-name" v-if="item.subName">{{
          upperFirst(item.subName)
        }}</span>
        <icon-veggie v-if="item.veggie" size="20"></icon-veggie>
      </div>
      <div class="item-caption">{{ item.caption }}</div>
      <ul class="item-content">
        <li v-for="line in item.content" :key="line">{{ line }}</li>
      </ul>
      <div class="item-price" v-if="item.price">
        <template v-if="typeof item.price === 'object'">
          <ul v-for="(price, key) of item.price" :key="price">
            <li class="price-name">{{ key }}</li>
            <li>{{ price }}€</li>
          </ul>
        </template>
        <template v-else> {{ item.price }}€ </template>
      </div>
    </div>
  </div>
</template>

<script>
import { upperFirst } from "lodash";

export default {
  name: "MenuCard",
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: ""
    }
  },
  methods: {
    upperFirst
  }
};
</script>
