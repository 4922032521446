<style lang="scss" scoped src="../../styles/menu.scss"></style>

<template>
  <header class="modal-header">
    <h1 class="modal-title">{{ menu.title }}</h1>
    <p class="modal-subtitle">{{ menu.caption }}</p>
  </header>
  <main class="modal-content">
    <!-- categories items loop -->
    <div
      class="menu-grid"
      v-for="category in menu.categories"
      :key="category.id"
    >
      <div class="menu-category">{{ category.name }}</div>
      <!-- items -->
      <menu-card
        v-for="item in category.items"
        :type="item.type"
        :item="item"
        :key="item.id"
        :class="item.wide ? 'wide' : ''"
      ></menu-card>
    </div>

    <div class="menu-infos">
      <p v-if="menu.veggie"><icon-veggie>Veggie</icon-veggie> Veggie</p>
      <p v-if="menu.infos">{{ menu.infos }}</p>
      <p>
        Prix nets en euros - Service compris - Boissons non comprise<br />
        La maison n'accepte pas les chèques.
      </p>
    </div>
  </main>
  <footer class="modal-footer" @click="$router.go(-1)">
    <span>Fermer</span>
  </footer>
</template>

<script>
import MenuCard from "@/views/menu/MenuCard";

export default {
  components: {
    MenuCard
  },
  computed: {
    menu() {
      const path = this.$route.path.split("/");
      const uri = path[path.length - 1];
      return this.$store.getters["menu/getMenu"](uri);
    }
  }
};
</script>
